import { useState, useRef, useEffect, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import FullScreenQRCodeReader from './FullScreenQRCodeReader'
import Spinner from './Spinner'
import { subscribe } from '../utils/pubsub'
import Modal from './Modal'
import { validateForm } from '../utils/validateForm'
import CountDownTimer from './CountdownTimer'
import Card from './Card'
import Button from '../formfields/Button'
import ProgressBar from '../formfields/ProgressBar'
import React from 'react'
import Radio from '../formfields/Radio'
import EnabledDisabled from '../formfields/EnabledDisabled'
import { convertDateToTimestamp } from '../utils/convertDateToTimestamp'
import { useNavigate } from 'react-router-dom';



const MiniumumConfidenceRequiredToUseAIReading = 0.2

type ObjectAny = {
  [key: string]: any
}

type Props = {
  socket: WebSocket | null
  accessToken: string | null
  idToken: string | null
  setFridges: React.Dispatch<React.SetStateAction<any>>
  lastCodeDetected: string | null,
  setLastCodeDetected: React.Dispatch<React.SetStateAction<string | null>>
  currentGroupId: string | null,
  checkingTimesForGroups: any,
  groups: any,
  setCurrentGroupId: React.Dispatch<React.SetStateAction<string | null>>,
  aiImageWorker: Worker | null
  logoutUrl: string
  loginUrl: string
  loggedInState: string | null
  loggedInStateRef: React.Ref<string | null>
  setLoggedInState: React.Dispatch<React.SetStateAction<any>>
  setAssetToFindOrgFrom: React.Dispatch<React.SetStateAction<string | null>>
  assetToFindOrgFrom: string | null
  setUserData: React.Dispatch<React.SetStateAction<any>>
  unknownCodeLoginOptions: any
  pauseCodeDetection: boolean
}


type CheckedProgress = { [key: string]: any }

type CheckNotExpectedProps = {
  brandstyles: { [key: string]: any }
  tableData: ObjectAny | null
  currentChecks: ObjectAny | null
  isCheckingComplete: boolean
  currentGroupId: string | null
  checkingTimesForGroups: any
  groups: any
  setStatus: React.Dispatch<React.SetStateAction<any>>
  showPhone?: boolean
}

type CheckProgressProps = {
  brandstyles: { [key: string]: any }
  tableData: ObjectAny | null
  currentChecks: ObjectAny | null
  isCheckingComplete: boolean
  currentGroupId: string | null
  checkingTimesForGroups: any
  groups: any
  setCurrentGroupId: React.Dispatch<React.SetStateAction<string | null>>
  showPhone?: boolean
}

type CheckedProps = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  showPhone?: boolean
}

type NotThisOrgProps = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  showPhone?: boolean
  unknownCodeLoginOptions: any,
  assetToFindOrgFrom: string | null,
  loginUrl: string,
  setLoggedInState: React.Dispatch<React.SetStateAction<any>>
}


type NoDigitsSetUpProps = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  fridgeId: string | null
  showPhone?: boolean,
  pauseVideoRef: any
}
type ConfirmationProps = {
  status: string
  setStatus: React.Dispatch<React.SetStateAction<string>>
  imageToSave: string | null
  assetId: string | null,
  updateAppliancesChecked: (assetId: string | null) => void,
  saveReading: (assetId: string | null, formFields: ObjectAny) => void,
  setLastCodeSaved: React.Dispatch<React.SetStateAction<any>>,
  aiRecognizerState: string,
  noQrCanvasRef: React.RefObject<HTMLCanvasElement> | null,
  temperature: string | null,
  setBestConfidence: React.Dispatch<React.SetStateAction<number>>
  showPhone?: boolean,
  pauseVideoRef: any
}


type FridgeIndividualCheckProps = {
  isFridgeChecked: boolean
  fridgesForProgress: ObjectAny
  appliance: string
  currentChecks: ObjectAny | null
  tableData: ObjectAny | null
}

function insertDot(str: string, n: number) {
  // Check if the number is within the range of string length
  if (n > 0 && n <= str.length) {
    // Calculate the position to insert the dot
    let position = str.length - n;
    // Insert the dot using substring and return the new string
    return str.substring(0, position) + '.' + str.substring(position);
  } else {
    // If the number is out of range, return the original string or handle as needed
    return str;
  }
}

function Confirmation({
  status,
  setStatus,
  imageToSave,
  assetId,
  updateAppliancesChecked,
  setLastCodeSaved,
  saveReading,
  aiRecognizerState,
  noQrCanvasRef,
  temperature,
  setBestConfidence,
  showPhone,
  pauseVideoRef
}: ConfirmationProps) {


  const {
    brandstyles,
    tableData,
    userData,
    // fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  useEffect(() => {
    //console.log("temperature changed useEffect")
    if (temperature && formFields && !formFields['manuallyEdited']) {
      const newTemperature = { ...formFields }
      newTemperature['Temperature'] ||= {}
      newTemperature['Temperature']['value'] = temperature
      //console.log("❄️")


      setFormFields(newTemperature)
    }
  }, [temperature])




  const initialFormData = {
    "Temperature": { "value": '', "required": true, "type": "text" },
    "Notes": { "value": '', "required": false, "type": "text" }
  }
  const [formFields, setFormFields] = useState<ObjectAny>(initialFormData)



  function saveClicked() {
    setStatus('saving')
    setLastCodeSaved({ assetId: assetId, timeStamp: Date.now() })
    saveReading(assetId, formFields)
    setBestConfidence(0)
  }


  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectAny = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    if (key === 'Temperature') {
      newFieldValues['Temperature'] = { ...newFieldValues['Temperature'], 'manuallyEdited': true }
    }
    setFormFields(newFieldValues)
  }




  return <Modal
    showPhone={showPhone}>


    <img src={`${imageToSave}`} alt='' className={`w-full rounded mb-4`} />



    <div className={`flex flex-col gap-4`}>
      <div className={`flex flex-col gap-2 items-start`}>
        <h3 className={`text-xl font-bold text-brandmidblue pb-4`}>Reading detected for: {tableData && assetId && tableData.Assets[assetId].AssetName}</h3>



        

        {tableData && assetId && `${tableData.Assets[assetId]['HasDigitalReadout']}` === `true` && temperature && <p>
          Edit the reading below if required then save.
        </p>}

        {tableData && assetId && `${tableData.Assets[assetId]['HasDigitalReadout']}` !== `true` && <p>
          This fridge does not have a digital display. Please enter the temperature reading manually.
        </p>}



        <input type='number'
          className={`w-full bg-gray-200 border border-gray-300 text-gray-900 text-xl font-bold text-center rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}
          value={formFields['Temperature'] ? formFields['Temperature']['value'] : ''}
          onChange={(e) => handleChange('Temperature', e.target.value)}
        />
        {formFields['Temperature'] && formFields['Temperature']['isValid'] === false && <p className={`text-xs text-red-700`}>Temperature must be a valid number</p>}
      </div>

      <div className={`flex flex-col gap-2`}>
        <p>Notes:</p>
        <input type='text'
          className={`w-full bg-gray-200 border border-gray-300 text-gray-900 text-sm text-center rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}
          value={formFields['Notes'] ? formFields['Notes']['value'] : ''}
          onChange={(e) => handleChange('Notes', e.target.value)}
        />
        {formFields['Notes'] && formFields['Notes']['isValid'] === false && <p className={`text-xs text-red-700`}>Please enter a note</p>}
      </div>
      <div className={`mt-4 flex flex-row items-center gap-2`}>
        <button
          className={`w-full bg-brandmidblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}
          onClick={(e) => validateForm(e, formFields, setFormFields, saveClicked)}
        >Save</button>
        <button
          className={`w-full bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded hover:opacity-90`}
          onClick={() => {
            //console.log('set status 10 - cancelled dialog')
            setStatus('lookingForCode')
            setBestConfidence(0)
          }}
        >Cancel</button>
      </div>

      {(aiRecognizerState == 'loading') && "…"}
        {(aiRecognizerState == 'error_loading') && "🚫"}

    </div>
  </Modal >
}




function NoDigitsSetUp({
  setStatus,
  fridgeId,
  showPhone,
  pauseVideoRef
}: NoDigitsSetUpProps) {



  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const [savingProgress, setSavingProgress] = useState(false)
  const [HasDigitalReadout, setHasDigitalReadout] = useState<boolean | null>(null)
  const [DigitsAfterDecimal, setDigitsAfterDecimal] = useState<number | null>(null)


  const saveItem = () => {
    setSavingProgress(true)
    const values: ObjectAny = {}
    values['AssetType'] = 'Fridge'
    if (HasDigitalReadout !== null) {
      values['HasDigitalReadout'] = HasDigitalReadout
    }
    values['DigitsAfterDecimal'] = DigitsAfterDecimal
    const payload = JSON.stringify({
      Id: fridgeId,
      action: "fridgetemps",
      fridgeAction: "setDecimalPlaces",
      values: values
    })

    //console.log(JSON.stringify(values))
    sendMessageToWebsocket(payload)

    const unsubscribeUpdate = subscribe("tableUpdateItemModified", data => {
      setSavingProgress(false)
      unsubscribeUpdate()
    })
  }

  const toggleDigitalDisplay = (HasDigitalReadout: any, DigitsAfterDecimal: any) => {
    setHasDigitalReadout(HasDigitalReadout)
    setDigitsAfterDecimal(DigitsAfterDecimal)
  }



  return <Modal
    showPhone={showPhone} setShowModal={() => setStatus('lookingForCode')} showCloseButton={true}>
    {savingProgress && <Spinner>Saving...</Spinner>}
    {!savingProgress && <div className={`flex flex-col gap-2`}>


      <h3 className={`text-xl font-bold text-brandmidblue pb-2`}>
        This fridge ({fridgeId ? fridgeId : 'NULL'}) doesn't have a digital display set up</h3>

      <p className={``}>What type of display does this fridge have?</p>

      <div className={`flex flex-row gap-2`}> <Button
        onClick={() => {
          toggleDigitalDisplay(false, null)
        }}
        text={`No digital display`}
        variant={HasDigitalReadout === false ? 'primary' : 'gray'}
      />
        <Button
          onClick={() => {
            toggleDigitalDisplay(true, 0)
          }}
          text={`No decimal place`}
          variant={HasDigitalReadout === true && DigitsAfterDecimal === 0 ? 'primary' : 'gray'}
        />
        <Button
          onClick={() => {

            toggleDigitalDisplay(true, 1)
          }}
          text={`One decimal place`}
          variant={HasDigitalReadout === true && DigitsAfterDecimal === 1 ? 'primary' : 'gray'}
        />
      </div>


      {HasDigitalReadout !== null && <div className={`flex flex-row gap-2`}>
        <Button
          onClick={() => {
            setStatus('lookingForCode')
          }}
          text={`Cancel`}
          variant={`gray`}
        />
        <Button
          onClick={saveItem}
          text={`Save`}
          variant={`primary`}
        />
      </div>}
    </div>}
  </Modal>
}



function AlreadyChecked({
  setStatus,
  showPhone
}: CheckedProps) {

  return <Modal
    showPhone={showPhone}>
    <h3 className={`text-center text-xl font-bold text-brandmidblue pb-2`}>This fridge or freezer has already been checked</h3>

    <div className={`mt-2 flex flex-col gap-2`}>
      <Button
        onClick={() => setStatus('lookingForCode')}
        text={'Cancel'}
        variant={'gray'}
        size={'big'}
      />
      <Button
        onClick={() => setStatus('confirmation')}
        text={'Take another reading for this fridge'}
        variant={'primary'}
        size={'big'}
      />
    </div>

  </Modal>
}



function NotThisOrg({
  setStatus,
  showPhone,
  unknownCodeLoginOptions,
  setLoggedInState,
  assetToFindOrgFrom,
  loginUrl
}: NotThisOrgProps) {
  let navigate = useNavigate();

  const {
    brandstyles,
    tableData,
    userData,
    // fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  let org_from_sticker = {}
  if (unknownCodeLoginOptions && unknownCodeLoginOptions.org_id) {
    const organisations = userData ? JSON.parse(userData.organisationsForUser) : {}
    org_from_sticker = organisations[unknownCodeLoginOptions.org_id]
  }

  return <Modal
    showPhone={showPhone}>
    <div className={`flex flex-col items-center justify-center text-center gap-2`}>
      <h3 className={`text-xl font-bold text-brandmidblue`}>This QR sticker does not belong to the account you are logged in to</h3>

      {unknownCodeLoginOptions === null && <Spinner><p>Looking up code...</p></Spinner>}

      {unknownCodeLoginOptions && unknownCodeLoginOptions.types.includes('error') && <p className={``}>Error: {unknownCodeLoginOptions['error']}</p>}

      <div className={`w-full mt-2 flex flex-col gap-2`}>
        {unknownCodeLoginOptions && unknownCodeLoginOptions.types.includes('guest') && <Button
          onClick={() => {
            navigate(`/${assetToFindOrgFrom}`)
            setLoggedInState('guestLoginPrompt')
          }}
          text={'Switch to that organisation'}
          variant={'primary'}
          size={'big'}
        />}


        {unknownCodeLoginOptions && unknownCodeLoginOptions.types.includes('cognito') && !unknownCodeLoginOptions.types.includes('guest') && <Button
          onClick={() => {
            window.location.href = loginUrl
          }}
          text={'Log in with a different email address'}
          variant={'primary'}
          size={'big'}
        />}



        {unknownCodeLoginOptions && unknownCodeLoginOptions.types.includes('switch') &&
          <Button
            onClick={() => {
              switchCurrentOrganisation(unknownCodeLoginOptions.org_id, true)
            }}
            text={`Switch to ${org_from_sticker} `}
            variant={'primary'}
            size={'big'}
          />}

        <Button
          onClick={() => setStatus('lookingForCode')}
          text={'Cancel'}
          variant={'gray'}
          size={'big'}
        />
      </div>
    </div>
  </Modal>
}




function CheckNotExpected({
  brandstyles,
  tableData,
  currentChecks,
  isCheckingComplete,
  currentGroupId,
  checkingTimesForGroups,
  groups,
  setStatus,
  showPhone
}: CheckNotExpectedProps) {

  const [groupName, setGroupName] = useState<string | null>(null)
  const [checkPeriodNames, setCheckPeriodNames] = useState<Array<string>>([])

  useEffect(() => {
    if (currentGroupId && groups) {
      setGroupName(groups[currentGroupId].GroupName)
    }
  }, [currentGroupId, groups])


  useEffect(() => {
    if (checkingTimesForGroups && currentGroupId) {
      const checksForThisGroup = checkingTimesForGroups[currentGroupId].checks
      const checkPeriodNames: string[] = []
      Object.keys(checksForThisGroup).forEach((key) => {
        const thisCheck = checksForThisGroup[key]
        const thisCheckName = `${thisCheck.start.hours}:${thisCheck.start.minutes}-${thisCheck.end.hours}:${thisCheck.end.minutes}`
        //console.log(thisCheck)
        checkPeriodNames.push(thisCheckName)
        setCheckPeriodNames(checkPeriodNames)
      })
    }
  }, [currentGroupId, checkingTimesForGroups])



  return <Modal
    showPhone={showPhone}>
    <h3 className={`text-center text-xl font-bold text-brandmidblue pb-2`}>No check expected right now</h3>


    <p className={`text-center`}>Readings are only expected
      {groupName && <> for the <span className={`font-bold`}>{groupName}</span> group </>}
      within the following check periods:

      {checkPeriodNames.map((thisCheckName, index) => {
      return <span key={index} className={`font-bold`}>
        {` ${thisCheckName}`}{index + 1 < checkPeriodNames.length ? ',' : ''}
      </span>
    })}.</p>



    <p className={`mt-2 text-center`}>Admin users may log in to your dashboard to change the check periods or to manually add a check.</p>

    {/* <div className={`mt-3 flex sm:flex-row sm:px-2 sm:py-1 flex-col px-3 py-3 items-center gap-3 rounded border border-yellow-400 bg-yellow-100 text-yellow-800`}>
      <img src={`/tip-icon.png`} className={`sm:w-6 sm:h-6 w-12 h-12`} alt='' />
      <p className={`text-xs sm:text-left text-center`}><span className={`font-bold`}>Checks can only be added within a set check period.</span> Check period start and end times can be edited in the Groups page on your dashboard. Manual entries can be added through the Listings page on your dashboard.</p>
    </div> */}

    <button
      className={`w-full mt-4 bg-brandmidblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}
      onClick={() => setStatus('lookingForCode')}
    >OK</button>
  </Modal>
}



function FullScreenFridgeScanner({
  socket,
  accessToken,
  idToken,
  setFridges,
  lastCodeDetected,
  setLastCodeDetected,
  currentGroupId,
  checkingTimesForGroups,
  groups,
  setCurrentGroupId,
  aiImageWorker,
  logoutUrl,
  loginUrl,
  loggedInState,
  loggedInStateRef,
  setLoggedInState,
  setUserData,
  unknownCodeLoginOptions,
  setAssetToFindOrgFrom,
  assetToFindOrgFrom,
  pauseCodeDetection
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const [status, setStatus] = useState<string>('lookingForCode')
  const [sounds, setSounds] = useState<any>({})
  const [lastCodeSaved, setLastCodeSaved] = useState<ObjectAny | null>(null)
  const [lastImageCapture, setLastImageCapture] = useState<string | null>(null)
  const [bestConfidence, setBestConfidence] = useState(0)
  const [currentChecks, setCurrentChecks] = useState<ObjectAny | null>(null)
  const [isCheckingComplete, setIsCheckingComplete] = useState(false)
  const [areWeInACheckPeriodNow, setAreWeInACheckPeriodNow] = useState(false)
  const [showStartDate, setShowStartDate] = useState<string | null>(`${Date.now()}`)
  const [aiRecognizerState, setAiRecognizerState] = useState<string>('not_yet_loaded')
  const noQrCanvasRef = useRef<HTMLCanvasElement>(null)
  const straightenedCanvasRef = useRef<HTMLCanvasElement>(null)
  const lastCodeSavedRef = useRef<ObjectAny | null>(null)
  const lastCodeDetectedRef = useRef<string | null>(null)
  const currentChecksRef = useRef<ObjectAny | null>(null)
  const areWeInACheckPeriodNowRef = useRef<boolean>(true)
  const currentGroupIdRef = useRef<string | null>(null)
  const groupsRef = useRef<ObjectAny | null>(null)
  const [temperature, setTemperature] = useState<string | null>(null)
  const [imageToSave, setImageToSave] = useState<string | null>(null)
  const [trainingDataToSave, setTrainingDataToSave] = useState<ObjectAny | null>(null)
  const [canvasWidth, setCanvasWidth] = useState<number | null>(null)
  const [canvasHeight, setCanvasHeight] = useState<number | null>(null)

  const pauseCodeDetectionRef = useRef(pauseCodeDetection)
  const tableDataRef = useRef(null)
  const statusRef = useRef(null)
  const bestConfidenceRef = useRef<number>(null)
  const assetsToCheck = fridges ? Object.keys(fridges) : []
  const pauseVideoRef = useRef<boolean>(false)


  let showPhone = false
  const queryString = window.location.search
  if (queryString.includes('phone')) {
    showPhone = true
  }
  const isIphone = window.navigator.userAgent.includes('iP')
  const isIos = window.navigator.userAgent.includes('Mac OS')
  const phoneWidth = 340
  const phoneHeight = 700
  let overlayElementsDivHeight = '95%'
  if (isIphone) {
    overlayElementsDivHeight = '86%'
  } else if (isIos) {
    overlayElementsDivHeight = '95%'
  }



  useEffect(() => {
    pauseCodeDetectionRef.current = pauseCodeDetection
  }, [pauseCodeDetection])


  useEffect(() => {
    if (bestConfidence == 0) {
      setTemperature('')
    }
  }, [bestConfidence])

  useEffect(() => {
    if (aiImageWorker) {
      let model_name = localStorage.getItem("debug_ai_model_name")
      if (model_name == null) {
        model_name = "march2024"
      }

      aiImageWorker.onmessage = (e: any) => {
        //console.log("got message from worker")
        const messageData = e.data
        switch (messageData.action) {
          case 'setAiRecognizerState':
            setAiRecognizerState(messageData.value)
            if(messageData.value == 'error_loading') {
              console.log("🤖 AI recognizer reported error loading model")
              setTimeout( ()=>{
                aiImageWorker.postMessage({ "action": "loadModel", "model": model_name })
              }, 5000)
            } else {
              console.log(`🤖 AI recognizer state is ${messageData.value}`)

            }
            break;
          case 'setAiResult':
            pauseVideoRef.current = false
            //console.log("Got AI result from worker ")
            const confidence = messageData.result.confidence
            const temperature_reading = messageData.result.reading
            const image_data_to_save = messageData.result.image_data_to_save
            if (confidence > bestConfidenceRef.current! && confidence > MiniumumConfidenceRequiredToUseAIReading) {
              //@ts-ignore
              bestConfidenceRef.current = confidence
              //console.log(`New best confidence ${bestConfidenceRef.current}`)
              setBestConfidence(confidence)
              setTemperature(temperature_reading)
              setImageToSave(image_data_to_save)
              setTrainingDataToSave({
                aiModelOutput: messageData.result.rawAiResult,
                image: messageData.result.image_data_for_training,
                height: `${messageData.result.training_height}`,
                width: `${messageData.result.training_width}`
              })
            }

            break;


        }

      }
      aiImageWorker.postMessage({ "action": "loadModel", "model": model_name })

    }

  }, [aiImageWorker])

  useEffect(() => {
    areWeInACheckPeriodNowRef.current = areWeInACheckPeriodNow
  }, [areWeInACheckPeriodNow])

  useEffect(() => {
    currentGroupIdRef.current = currentGroupId
  }, [currentGroupId])

  useEffect(() => {
    groupsRef.current = groups
  }, [groups])

  useEffect(() => {
    if (aiRecognizerState == 'ready' && aiImageWorker && noQrCanvasRef && straightenedCanvasRef.current && noQrCanvasRef.current) {
      const context = noQrCanvasRef.current.getContext("2d", { willReadFrequently: true })
      const width = noQrCanvasRef.current.width
      const height = noQrCanvasRef.current.height
      const image_data_for_training = noQrCanvasRef.current.toDataURL('image/jpeg')
      //@ts-ignore
      const image_data = context!.getImageData(0, 0, width, height)
      const image_data_to_save = straightenedCanvasRef.current.toDataURL('image/jpeg')
      const training_width = noQrCanvasRef.current.width
      const training_height = noQrCanvasRef.current.height
      pauseVideoRef.current = true


      let currentAssetId = lastCodeDetectedRef.current
      if (
        currentAssetId &&
        tableData &&
        tableData.Assets &&
        tableData.Assets[currentAssetId] &&
        tableData.Assets[currentAssetId]['HasDigitalReadout'] === true
      ) {
        console.log('🧧 has digital readout - get AI reading')
        const currentAsset = tableData.Assets[currentAssetId]
        const digits_after_decimal = currentAsset['DigitsAfterDecimal']
        const currentAssetProfile = tableDataRef.current && tableDataRef.current!['AssetProfiles'] && currentAsset && tableDataRef.current['AssetProfiles'][currentAsset['ProfileId']]
        const expectedMaxTemperature = currentAssetProfile && parseInt(currentAssetProfile['ExpectedMaxTemp']) || 0

        aiImageWorker.postMessage({ "action": "setExpectedReadingDetails", digits_after_decimal, expectedMaxTemperature })


        aiImageWorker.postMessage({ "action": "getReadingWithAIModel", "image_data": image_data, "image_data_to_save": image_data_to_save, "image_data_for_training": image_data_for_training, training_width, training_height })
      } else {
        console.log('🧧 no digital readout - do not get AI reading')
      }


    }
  }, [lastImageCapture])


  useEffect(() => {
    const soundsHash: any = {}
    soundsHash['oneFridge'] = new Audio('/sounds/oneFridge.mp3')
    soundsHash['allFridges'] = new Audio('/sounds/allFridges.mp3')
    setSounds(soundsHash)
  }, [])

  useEffect(() => {

    tableDataRef.current = { ...tableData }

    if (tableData && fridges && !tableData.Checks) {
      fetchData()
    }

    if (tableData && tableData.Checks && fridges) {
      const newCurrentChecks: ObjectAny = {}
      let fridgesInCurrentGroup: string[] = []
      const group_id = currentGroupIdRef
      if (groups && currentGroupId && groups[currentGroupId] && groups[currentGroupId]['Fridges']) {
        fridgesInCurrentGroup = Object.keys(groups[currentGroupId]['Fridges'])
      }

      let window_start: string | null = null
      let window_end: string | null = null


      if (checkingTimesForGroups && currentGroupId && checkingTimesForGroups[currentGroupId]) {
        let current_group_check_details = checkingTimesForGroups[currentGroupId]
        let we_are_in_a_check = current_group_check_details['current_check']['we_are_in_a_check']
        if (we_are_in_a_check) {
          setAreWeInACheckPeriodNow(true)
          window_start = current_group_check_details['current_check']['check_started_at']
          window_end = current_group_check_details['current_check']['check_finishes_at']
        } else {
          setAreWeInACheckPeriodNow(false)
          window_end = current_group_check_details['current_check']['last_check_finished_at']
          window_start = current_group_check_details['current_check']['last_check_started_at']
        }
      }


      Object.keys(tableData.Checks).forEach((checkId: any, value: any) => {
        //const isFromToday = getDate(tableData.Checks[checkId].TimeStamp, 'formattedDate') === getDate(rightNow, 'formattedDate') ? true : false
        const isFromToday = window_start && window_end && isTimestampBetween(parseInt(tableData.Checks[checkId].TimeStamp), window_start, window_end)
        const isInCurrentGroup = fridgesInCurrentGroup.indexOf(tableData.Checks[checkId].AssetId) !== -1
        if (isInCurrentGroup && isFromToday) {
          const fridge_id = tableData.Checks[checkId].AssetId
          newCurrentChecks[fridge_id] = tableData.Checks[checkId]
        }
      })

      if (Object.keys(newCurrentChecks).length > 0 && Object.keys(newCurrentChecks).length === Object.keys(fridgesInCurrentGroup).length) {
        setIsCheckingComplete(true)
        //setStatus("allchecked")
        setStatus('lookingForCode')
        playSound('allFridges')
      } else {
        setIsCheckingComplete(false)
        if (status !== 'confirmation') {
          setStatus('lookingForCode')
        }
      }
      setCurrentChecks(newCurrentChecks)
      currentChecksRef.current = newCurrentChecks
    }
  }, [tableData, groups, currentGroupId, checkingTimesForGroups, fridges])



  useEffect(() => {
    if (lastCodeSaved) {
      lastCodeSavedRef.current = lastCodeSaved
    }
  }, [lastCodeSaved])


  useEffect(() => {
    // 🔈 this is where it goes ding
    // if (status == 'allchecked') {
    //   playSound('allFridges')
    // }
    if (status == 'confirmation') {
      playSound('oneFridge')
    }

  }, [status])


  useEffect(() => {
    //@ts-ignore
    statusRef.current = status
    if (status === 'lookingForCode') {
      pauseVideoRef.current = false
    }
  }, [status])

  useEffect(() => {
    //@ts-ignore
    bestConfidenceRef.current = bestConfidence
  }, [bestConfidence])



  useEffect(() => {
    //@ts-ignore
    lastCodeDetectedRef.current = lastCodeDetected
  }, [lastCodeDetected])




  const playSound = (name: string) => {
    if (sounds[name]) {
      sounds[name].currentTime = 0
      sounds[name].play().catch(console.error); //
    }
  }


  const fetchData = () => {
    if (socket && showStartDate && fridges) {

      const payloadObj: ObjectAny = {
        action: 'fridgetemps',
        fridgeAction: "requestCurrentChecks",
        assetsToCheck: assetsToCheck,
        // endDate: '1710331875000' THIS IS OPTIONAL
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }


  function isTimestampBetween(timestamp: number, startDateString: string, endDateString: string) {
    // Convert the date strings to Date objects
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Get the Unix timestamps in milliseconds
    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime();

    // Check if the provided timestamp is between the start and end timestamps
    return timestamp >= startTimestamp && timestamp <= endTimestamp;
  }

  const saveReading = (assetId: string | null, formFields: ObjectAny) => {
    console.log("saveReading")
    const timeStamp = Date.now()
    const waitingForUploadUrlId = `${assetId}_${timeStamp}.jpeg`
    const imageDataToSave = imageToSave
    if (assetId) {
      const assetValues: ObjectAny = {
        AssetId: `${assetId}`,
        Reading: `${formFields.Temperature.value}`,
        manuallyEdited: formFields.Temperature.manuallyEdited,
        Notes: formFields.Notes.value,
        Confidence: `${bestConfidenceRef.current}`,
        TimeStamp: `${timeStamp}`
      }

      if (userData!['cognito:username']) {
        assetValues['UserId'] = userData['cognito:username']
      }

      if (loggedInState === 'guest' && userData!['name']) {
        assetValues['GuestUserName'] = userData['name']
      }


      const payloadObj: { [index: string]: any } = {
        action: "fridgetemps",
        fridgeAction: "saveTemperatureReading",
        tableName: "Checks",
        values: assetValues,
        waitingForUploadUrlId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("addItemSignedUploadURL", async (data) => {
        //console.log("addItemSignedUploadURL listner invoked")
        if (data.waitingForUploadUrlId == waitingForUploadUrlId) {
          setStatus('lookingForCode')
          unsubscribe()
          const prefix = 'data:image/jpeg;base64,'
          const fileBody = imageDataToSave!.split(prefix)[1]
          const file = new File([b64toBlob(fileBody)], waitingForUploadUrlId, { type: "image/jpeg" })
          await fetch(data.signedUrl, {
            method: 'PUT',
            body: file
          })
            .then(response => {
              return (response)
            }).catch((error) => {
              console.error('Error:', error)
            })

        }
      })

      if (trainingDataToSave) {
        const trainingPayload: { [index: string]: any } = {
          action: "fridgetemps",
          fridgeAction: "saveTrainingData",
          aiModelOutput: trainingDataToSave!.aiModelOutput,
          image: trainingDataToSave!.image,
          width: trainingDataToSave!.width,
          height: trainingDataToSave!.height
        }
        sendMessageToWebsocket(JSON.stringify(trainingPayload))
        setTrainingDataToSave(null)
      }

    }

  }

  const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }



  const updateAppliancesChecked = (assetId: string | null, timeStamp?: number | null) => {
    timeStamp ||= Date.now()
    if (assetId) {
      const newData: CheckedProgress = { ...fridges }
      newData[assetId].checked = true
      newData[assetId].timeStamp = timeStamp
      setFridges(newData)
      //console.log("setting status lookingForCode 2")
      setStatus('lookingForCode')
      const numberOfAppliances = Object.keys(newData).length
      const numberOfCheckedAppliances = Object.values(newData).filter((value) => value.checked === true).length
      const allChecked = numberOfAppliances === numberOfCheckedAppliances
      if (allChecked) {
        //console.log("setting status allchecked 4")
        setStatus("allchecked")
      }
    }
  }



  const getAllFridgeIds = () => {
    const groups = groupsRef.current
    let allFridges: string[] = []
    if (groups) {
      for (const [id, group] of Object.entries(groups!)) {
        //@ts-ignore
        if (group && group['Fridges']) {
          //@ts-ignore
          const fridges_in_this_group = Object.keys(group['Fridges'])
          allFridges = [...allFridges, ...fridges_in_this_group]
        }
      }
    }
    return allFridges
  }





  const onCodeFound = (results: any, imageAsStringWithQR: string, imageAsStringWithoutQR: string, training_image_width: number, training_image_height: number) => {
    console.log("Code found")

    if (pauseCodeDetectionRef.current === true) {
      // console.log("Code detection paused")
    } else {

      const codeUrlParts = results.codeUrl.split('/')
      const assetId = codeUrlParts[codeUrlParts.length - 1]
      const assetDomain = codeUrlParts[codeUrlParts.length - 2]
      const timeNow = Date.now()
      const lastCodeSavedId = lastCodeSavedRef.current ? lastCodeSavedRef.current!.assetId : null
      const lastCodeSavedTimestamp = lastCodeSavedRef.current ? lastCodeSavedRef.current!.timeStamp : timeNow
      const timeSinceLastSaved = timeNow - lastCodeSavedTimestamp
      const alreadyCheckedThreshold = 6000


      if (statusRef.current == 'saving') {
        //console.log("code found during saving - ignored")
        return
      }

      //
      // - looking for code state: - switch to confirmation and show reading
      // - confirmation state: - is it better? update it

      const group_id = currentGroupIdRef.current
      const groups = groupsRef.current
      const id_valid = (getAllFridgeIds().indexOf(assetId) !== -1)
      const inCurrentGroup = groups && group_id && Object.keys(groups[group_id].Fridges).indexOf(assetId) !== -1
      const currentAsset = tableDataRef.current && tableDataRef.current!['Assets'] && tableDataRef.current!['Assets'][assetId]
      //@ts-ignore

      if (!id_valid) {
        console.log(`ID is not valid for this org ${assetId} `)
        //console.log(assetDomain)
        setAssetToFindOrgFrom(assetId)
        if (statusRef.current === 'lookingForCode') {
          console.log(`Lookng up org for the code`)
          setStatus('not_valid_for_org')
          const stickerId = assetId
          const payloadObj: { [index: string]: string } = {
            action: "guestLogin",
            'guestLoginAction': 'requestLoginActionForSticker',
            'stickerId': stickerId
          }
          const payload = JSON.stringify(payloadObj)
          sendMessageToWebsocket(payload)
        }
        return
      }

      if (statusRef.current == 'not_valid_for_org' && id_valid) {
        setStatus('lookingForCode')
      }


      // if we're looking for a code then we should go to the confirmation state
      // unless we already detected this, then we ignore it
      if (statusRef.current === 'lookingForCode') {
        if (assetId === lastCodeSavedId) {
          if (timeSinceLastSaved > alreadyCheckedThreshold) {
            setStatus('alreadychecked')
          } else {
            // do nothing if the code detected has already been checked recently
          }
        } else if (currentChecksRef.current && currentChecksRef.current[assetId]) {
          setStatus('alreadychecked')
        } else if (!areWeInACheckPeriodNowRef.current) {
          setStatus('checknotexpected')
        } else if (inCurrentGroup) {
          if (currentAsset!['HasDigitalReadout'] !== true && currentAsset!['HasDigitalReadout'] !== false) {
            //@ts-ignore
            statusRef.current = 'nodigits'
            setStatus('nodigits')
          } else {
            setStatus('confirmation')
            //@ts-ignore
            statusRef.current = 'confirmation'
          }
          // setLastImageCapture(imageAsStringWithQR)
          // setImageToSave(imageAsStringWithQR)
          // setLastCodeDetected(assetId)
          // lastCodeDetectedRef.current = assetId
        } else {
          // setLastImageCapture(imageAsStringWithQR)
          // setImageToSave(imageAsStringWithQR)
          // setLastCodeDetected(assetId)
          // lastCodeDetectedRef.current = assetId
        }
        setLastImageCapture(imageAsStringWithQR)
        setImageToSave(imageAsStringWithQR)
        setLastCodeDetected(assetId)
        lastCodeDetectedRef.current = assetId
      }


      // When we're in the confirmation state we should update the image
      // in case it gets a higher confidence from the AI reading
      if (statusRef.current === 'confirmation') {
        if (lastCodeDetectedRef.current === assetId) {
          setLastImageCapture(imageAsStringWithQR)
        } else {
          //console.log("different fridge")
        }
      }

      // if we're showing the 'already checked' or 'check not expected' dialog
      // and we're now seeing a different fridge, and the different fridge has not been checked...
      // ...then we can open a new dialog
      if (statusRef.current === 'alreadychecked') {
        if (lastCodeDetected !== assetId) {
          if (currentChecksRef.current && !currentChecksRef.current[assetId]) {
            setLastCodeDetected(assetId)
            setStatus('confirmation')
            setLastImageCapture(imageAsStringWithQR)
          }
        }
      }

      if (statusRef.current === 'checknotexpected') {
        if (inCurrentGroup) {
          if (areWeInACheckPeriodNowRef.current) {
            setStatus('confirmation')
            setLastImageCapture(imageAsStringWithQR)
            setLastCodeDetected(assetId)
          }
        } else {
          setLastImageCapture(imageAsStringWithQR)
          setLastCodeDetected(assetId)
        }
      }
    }
  }





  return <div className={`bg-white h-screen w-screen flex flex-col items-center justify-center`}>


    <div
      className={`${showPhone ? 'phoneoutline' : ''}`}
      style={{
        width: `${showPhone ? `${phoneWidth}px` : `auto`}`,
        height: `${showPhone ? `${phoneHeight}px` : `auto`}`,
      }}>







      {status === 'saving' &&
        <Modal
          showPhone={showPhone}>
          <Spinner><p>Updating...</p></Spinner>
        </Modal>
      }
      {status === 'nodigits' &&


        <NoDigitsSetUp
          showPhone={showPhone}
          setStatus={setStatus}
          fridgeId={lastCodeDetectedRef.current}
          pauseVideoRef={pauseVideoRef}
        />
      }
      {status === 'alreadychecked' &&
        <AlreadyChecked
          showPhone={showPhone}
          setStatus={setStatus}
        />
      }


      {status === 'not_valid_for_org' && <NotThisOrg
        showPhone={showPhone}
        setStatus={setStatus}
        unknownCodeLoginOptions={unknownCodeLoginOptions}
        setLoggedInState={setLoggedInState}
        assetToFindOrgFrom={assetToFindOrgFrom}
        loginUrl={loginUrl}
      />
      }
      {status === 'checknotexpected' && <CheckNotExpected
        showPhone={showPhone}
        setStatus={setStatus}
        brandstyles={brandstyles}
        tableData={tableData}
        currentChecks={currentChecks}
        isCheckingComplete={isCheckingComplete}
        currentGroupId={currentGroupId}
        checkingTimesForGroups={checkingTimesForGroups}
        groups={groups}
      />}

      {status === 'confirmation' &&
        <Confirmation
          showPhone={showPhone}
          status={status}
          setStatus={setStatus}
          imageToSave={imageToSave}
          assetId={lastCodeDetected}
          updateAppliancesChecked={updateAppliancesChecked}
          setLastCodeSaved={setLastCodeSaved}
          saveReading={saveReading}
          aiRecognizerState={aiRecognizerState}
          noQrCanvasRef={noQrCanvasRef}
          temperature={temperature}
          setBestConfidence={setBestConfidence}
          pauseVideoRef={pauseVideoRef}
        />
      }





      <FullScreenQRCodeReader
        logoutUrl={logoutUrl}
        loginUrl={loginUrl}
        onCodeFound={onCodeFound}
        noQrCanvasRef={noQrCanvasRef}
        straightenedCanvasRef={straightenedCanvasRef}
        status={status}
        currentChecks={currentChecks}
        isCheckingComplete={isCheckingComplete}
        currentGroupId={currentGroupId}
        checkingTimesForGroups={checkingTimesForGroups}
        groups={groups}
        setCurrentGroupId={setCurrentGroupId}
        setLoggedInState={setLoggedInState}
        setUserData={setUserData}
        setParentCanvasWidth={setCanvasWidth}
        setParentCanvasHeight={setCanvasHeight}
        pauseVideoRef={pauseVideoRef}
        showPhone={showPhone}
        phoneWidth={phoneWidth}
        phoneHeight={phoneHeight}
        overlayElementsDivHeight={overlayElementsDivHeight}
        pauseCodeDetection={pauseCodeDetection}
      />

      <canvas
        ref={noQrCanvasRef}
        width={600}
        height={250}
        className={`hidden w-full border border-1 border-red-400 rounded hover:opacity-90-md bg-gray-300`}
      ></canvas>
    </div>
  </div>
}

export default FullScreenFridgeScanner