import Children from 'react'
import XButton from '../formfields/XButton'

type Props = {
  children: any
  setShowModal?: any
  showCloseButton?: boolean
  closeButtonValue?: any
  showPhone?: boolean
}

function Modal({
  children,
  setShowModal,
  showCloseButton,
  closeButtonValue,
  showPhone
}: Props) {

  return <div className={`${showPhone ? 'absolute' : 'fixed'} z-50 top-0 left-0 min-h-full w-full bg-black bg-opacity-60 flex items-center justify-center p-6`}>
    <div
      className="rounded hover:opacity-90-xl w-full max-w-lg p-6 bg-white divide-y divide-gray-500 shadow-2xl overflow-y-scroll relative"
      style={{
        maxHeight: `${showPhone ? '560px' : '80vh'} `
      }}
    >

      {setShowModal && showCloseButton && <div className={`absolute right-0 top-0 m-1`}>
        <XButton clickFunction={() => {
        setShowModal(closeButtonValue || false)
      }} />
      </div>}
      <div className={`border-none`}>
        {children}
      </div>
    </div>
  </div>
}
export default Modal