import React, { useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import Modal from './Modal'
import Button from '../formfields/Button'
import { subscribe } from "../utils/pubsub"
import Spinner from '../components/Spinner'
import { useContext } from 'react'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  logoutUrl: string
  loginUrl: string
  setLoggedInState: React.Dispatch<React.SetStateAction<any>>
  setUserData: React.Dispatch<React.SetStateAction<any>>
  showPhone: boolean
  videoInputDevices: any
  selectedVideoInputDevice: any
  setSelectedVideoInputDevice: React.Dispatch<React.SetStateAction<any>>
}



type MenuProps = {
  organisations: ObjectAny | null
  setShowMenu: React.Dispatch<React.SetStateAction<any>>
  setShowOrgsMenu: React.Dispatch<React.SetStateAction<any>>
  showOrgsMenu: boolean
  numberOfOrganisationsForThisUser: number
  handleLogout: () => void,
  handleLogin: () => void,
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
  showPhone: boolean
  videoInputDevices: any
  selectedVideoInputDevice: any
  setSelectedVideoInputDevice: React.Dispatch<React.SetStateAction<any>>
  setShowVideoMenu: React.Dispatch<React.SetStateAction<any>>
  showVideoMenu: boolean
}





function Menu({
  showPhone,
  organisations,
  setShowMenu,
  setShowOrgsMenu,
  showOrgsMenu,
  numberOfOrganisationsForThisUser,
  handleLogout,
  handleLogin,
  savingProgress,
  setSavingProgress,
  videoInputDevices,
  selectedVideoInputDevice,
  setSelectedVideoInputDevice,
  setShowVideoMenu,
  showVideoMenu
}: MenuProps) {


  const navigate = useNavigate()

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)




  const switchCameraDevice = (deviceId: string) => {
    setShowMenu(false)
    setSelectedVideoInputDevice(deviceId)
    localStorage.setItem('selectedVideoInputDevice', deviceId)
    window.location.reload()
  }


  return <div className={`w-full flex flex-col items-end`}>
    <div
      style={{
        // backgroundColor: `rgba(${brandstyles.primarycolorrgb}, 1)`,
        width: '260px',
        top: `${showPhone ? '' : '58px'}`,
      }}
      className={`bg-white fixed p-2 rounded flex flex-col gap-1 text-white text-xs z-50`}
      onMouseLeave={() => setShowMenu(false)}
    >

      {/* <div className={`md:hidden flex flex-col items-end`}>
          <img
            src='/close-x.svg'
            alt='Menu'
            className={`w-5`}
            onClick={() => setShowMenu(false)} />
        </div> */}





      <div className={`flex flex-col pb-2 gap-1`}>
        <div className={`flex flex-row gap-2 items-center`}>
          <img src='/usericon-blue.svg' className={`w-5`} alt='' />
          <p className={`text-lg font-bold text-temperature-check-blue`}>{userData && userData!.name}</p>
        </div>
        {currentOrganisation && organisations && organisations[currentOrganisation] && <p className={`text-gray-800`}>{organisations[currentOrganisation]}</p>}
      </div>

      {switchCurrentOrganisation && numberOfOrganisationsForThisUser > 1 && <>
        <div
          className={`font-bold text-sm px-3 py-2 rounded hover:opacity-90`}
          style={{ backgroundColor: `rgba(${brandstyles.primarycolorrgb}, 1)` }}
        >

          <div className={`flex flex-row items-center gap-2`} onClick={() => setShowOrgsMenu(!showOrgsMenu)}>
            <p className={``}>Switch organisation</p>
            <img src='/arrow.svg' alt='' className={`h-3 ${showOrgsMenu && 'rotate-90'}`} />
          </div>


          {organisations && switchCurrentOrganisation && showOrgsMenu &&
            <div className={`pt-2 text-left`}>
              {
                Object.keys(organisations).map((id, index) => {
                  return <div
                    className={`${currentOrganisation === id && 'hidden'} underline py-1 font-normal`}
                    key={index}
                    onClick={() => {
                      setShowMenu(false)
                      switchCurrentOrganisation(id, true)
                    }}>
                    {organisations[id]}
                  </div>
                })
              }
            </div>}
        </div>
      </>}


      {videoInputDevices && videoInputDevices.length > 0 && <>
        <div
          className={`font-bold text-sm px-3 py-2 rounded hover:opacity-90`}
          style={{ backgroundColor: `rgba(${brandstyles.primarycolorrgb}, 1)` }}
        >

          <div className={`flex flex-row items-center gap-2`} onClick={() => setShowVideoMenu(!showVideoMenu)}>
            <p className={``}>Switch camera</p>
            <img src='/arrow.svg' alt='' className={`h-3 ${showVideoMenu && 'rotate-90'}`} />
          </div>

            {showVideoMenu && <div className={`pt-2 text-left`}>
              {
                videoInputDevices.map((device: ObjectAny, index: number) => {
                  return <div
                    className={`${selectedVideoInputDevice === device.deviceId ? 'font-bold' : 'font-normal'} py-1 hover:opacity-80 cursor-pointer`}
                    key={index}
                    onClick={() => switchCameraDevice(device.deviceId)}>
                      {selectedVideoInputDevice === device.deviceId && '✅ '} {device.label}
                  </div>
                })
              }
            </div>}
        </div>
      </>}



      {userData ?
        <Button
          onClick={handleLogout}
          text='Log out'
          variant={`primary`}
          align={'left'}
        /> :
        <Button
          onClick={handleLogin}
          text='Log in'
          variant={`primary`}
          align={'left'}
        />
      }




    </div>



  </div>
}




function FullScreenNav({
  logoutUrl,
  loginUrl,
  setLoggedInState,
  setUserData,
  showPhone,
  videoInputDevices,
  selectedVideoInputDevice,
  setSelectedVideoInputDevice
}: Props) {



  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  const handleLogin = () => {
    setLoggedInState('loggedOut')
    setUserData(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = loginUrl
  }

  const handleLogout = () => {
    setLoggedInState('loggedOut')
    setUserData(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    localStorage.removeItem('guestLogin')
    window.location.href = logoutUrl
  }


  const [savingProgress, setSavingProgress] = useState(false)
  const organisations = userData ? JSON.parse(userData.organisationsForUser) : {}
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false)
  const [showOrgsMenu, setShowOrgsMenu] = useState(false)
  const [showVideoMenu, setShowVideoMenu] = useState(true)
  const numberOfOrganisationsForThisUser = organisations ? Object.keys(organisations).length : 0





  return <div className={``}>


    <div className={`rounded p-2 shadow bg-white`}
      style={{
        width: `44px`,
        height: `44px`,
        zIndex: `100`
        // backgroundColor: `rgba(${brandstyles.primarycolorrgb}, 1)`
      }}
    >
      {showMenu ?
        <img
          src='/close-x-blue.svg'
          alt='Menu'
          className={`w-full cursor-pointer`}
          onClick={() => setShowMenu(false)} /> :
        <img
          src='/hamburger-blue.svg'
          alt='Menu'
          className={`w-full cursor-pointer`}
          onClick={() => setShowMenu(true)} />
      }
    </div>




    {showMenu && <Menu
      showPhone={showPhone}
      organisations={organisations}
      setShowMenu={setShowMenu}
      setShowOrgsMenu={setShowOrgsMenu}
      showOrgsMenu={showOrgsMenu}
      setShowVideoMenu={setShowVideoMenu}
      showVideoMenu={showVideoMenu}
      numberOfOrganisationsForThisUser={numberOfOrganisationsForThisUser}
      handleLogout={handleLogout}
      handleLogin={handleLogin}
      savingProgress={savingProgress}
      setSavingProgress={setSavingProgress}
      videoInputDevices={videoInputDevices}
      selectedVideoInputDevice={selectedVideoInputDevice}
      setSelectedVideoInputDevice={setSelectedVideoInputDevice}
    />
    }


  </div>
}
export default FullScreenNav