import { useEffect, useState, useRef } from 'react'
import Spinner from './Spinner';

type Props = {
  endTime: string
  text: string
}

function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return {
    h: String(hours).padStart(2, '0'),
    m: String(minutes).padStart(2, '0'),
    s: String(remainingSeconds).padStart(2, '0')
  };
}


function CountDownTimer({
  endTime, text
}: Props) {

  const [hours, setHours] = useState<string | number>(0)
  const [minutes, setMinutes] = useState<string | number>(0)
  const [seconds, setSeconds] = useState<string | number>(0)
  const [endTimeMillis, setEndTimeMillis] = useState<number>(0)
  const [currentTimeMillis, setCurrentTimeMillis] = useState<number>((new Date()).getTime())

  useEffect(() => {
    setInterval(() => {
      const currentTime = (new Date()).getTime()
      setCurrentTimeMillis(currentTime)
    }, 1000)
  }, [])

  useEffect(() => {
    setEndTimeMillis(new Date(endTime).getTime())
  }, [endTime])


  useEffect(() => {
    calculateTime()
  }, [currentTimeMillis])


  const calculateTime = () => {
    const time_remaining = Math.round((endTimeMillis - currentTimeMillis) / 1000)
    const time_remaining_formatted = formatTime(time_remaining)
    if (endTimeMillis > currentTimeMillis) {
      setHours(time_remaining_formatted.h)
      setMinutes(time_remaining_formatted.m)
      setSeconds(time_remaining_formatted.s)
    }
  }

  return <div className={`flex flex-col items-start`}>


    {!hours && !minutes && !seconds ?
      <Spinner><p className={`text-xs`}>Fetching check period details...</p></Spinner>
      :
      <p className={`text-xs`}>{`${text}: `}
        <span className={`font-bold`}>{` ${hours}:${minutes}:${seconds} `}</span>
      </p>

    }



  </div>
}
export default CountDownTimer