export const checkIfSubscriptionExpiringWithoutPayment = (
  tableData,
  subscriptionHasBeenChecked,
  setSubscriptionExpiringWithoutPayment,
  setSubscriptionHasBeenChecked
  ) => {
  if (tableData && tableData.Organisation && !subscriptionHasBeenChecked) {
    // console.log(`🚘 checking subscription for ${tableData.Organisation.OrganisationName}`)
    const dateNow = Date.now()
    const numberOfDays = 3
    const messageExpiryDate = dateNow - (1000 * 60 * 60 * 24 * numberOfDays)
    const localStorageDate = localStorage.getItem('seenSubscriptionExpiringWithoutPaymentWarning')
    let isSubscriptionExpiringWithoutPayment = false
    let triallingAnyFeatures = false
    let hasPaymentMethod = false
    let hasSeenWarningAlready = false

    if ( localStorageDate && parseInt(`${localStorageDate}`) > messageExpiryDate
    ){
      //console.log(`User has seen this warning already on ${getDate(localStorageDate)}`)
      hasSeenWarningAlready = true
    }
    if (tableData.Organisation.ActiveFeatures) {
      triallingAnyFeatures = Object.values(tableData.Organisation.ActiveFeatures).includes('trialing')
    }
    if (tableData.Organisation.PaymentMethodsCount && tableData.Organisation.PaymentMethodsCount > 0) {
      hasPaymentMethod = true
    }
    if (hasPaymentMethod === false && triallingAnyFeatures === true && !hasSeenWarningAlready) {
      isSubscriptionExpiringWithoutPayment = true
      localStorage.setItem('seenSubscriptionExpiringWithoutPaymentWarning', `${dateNow}`)
    }
    // console.log(`is sub expiring? ${isSubscriptionExpiringWithoutPayment ? 'YES' : 'no'}`)
    setSubscriptionExpiringWithoutPayment(isSubscriptionExpiringWithoutPayment)
    setSubscriptionHasBeenChecked(true)
  }
}