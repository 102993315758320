import React, { useState, useEffect, useRef, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { convertDateToTimestamp } from '../utils/convertDateToTimestamp'
import CountDownTimer from './CountdownTimer'
import Modal from './Modal'
import Spinner from './Spinner'
type ObjectAny = {
  [key: string]: any
}


type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<any>>
  currentGroupId: string | null
  checkingTimesForGroups: any,
  showPhone: boolean
}

type ProgressForGroupProps = {
  groupId: string | null
  checkingTimesForGroups: any,
}


function ProgressForGroup({
  groupId,
  checkingTimesForGroups,
}: ProgressForGroupProps) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  const [fridgesForProgress, setFridgesForProgress] = useState<ObjectAny>({})
  const [checkedFridges, setCheckedFridges] = useState<ObjectAny>({})
  const [uncheckedFridges, setUncheckedFridges] = useState<ObjectAny>({})
  const [numberOfAppliances, setNumberOfAppliances] = useState(0)
  const [progressPercent, setProgressPercent] = useState(0)
  const [weAreInACheck, setWeAreInACheck] = useState<boolean | null>(null)
  const [checkStart, setCheckStart] = useState<string | null>(null)
  const [checkEnd, setCheckEnd] = useState<string | null>(null)





  useEffect(() => {
    const newWeAreInACheck = groupId && checkingTimesForGroups && checkingTimesForGroups[groupId] && checkingTimesForGroups[groupId]['current_check']['we_are_in_a_check']
    const newCheckStart = newWeAreInACheck && groupId && checkingTimesForGroups ? checkingTimesForGroups[groupId]['current_check']['check_started_at'] : null
    const newCheckEnd = newWeAreInACheck && groupId && checkingTimesForGroups ? checkingTimesForGroups[groupId]['current_check']['check_finishes_at'] : null
    const newFridgesForProgress: ObjectAny = {}
    const newCheckedFridges: ObjectAny = {}
    const newUncheckedFridges: ObjectAny = {}
    const startTimestamp = convertDateToTimestamp(newCheckStart)
    const endTimestamp = convertDateToTimestamp(newCheckEnd)


    if (tableData && tableData.Checks) {
      Object.keys(fridges).forEach((fridgeId: any) => {
        if (fridges[fridgeId].GroupId === groupId) {
          newFridgesForProgress[fridgeId] = fridges[fridgeId]
          let hasThisFridgeBeenChecked = false

          if (endTimestamp && startTimestamp) {
            Object.keys(tableData.Checks).forEach((checkId) => {
              if (
                tableData.Checks[checkId].AssetId === fridgeId &&
                parseInt(tableData.Checks[checkId].TimeStamp) > startTimestamp &&
                parseInt(tableData.Checks[checkId].TimeStamp) < endTimestamp
              ) {
                hasThisFridgeBeenChecked = true
              }
            })
          }

          if (hasThisFridgeBeenChecked) {
            newCheckedFridges[fridgeId] = fridges[fridgeId]
          } else {
            newUncheckedFridges[fridgeId] = fridges[fridgeId]
          }
        }
      })
    }

    setCheckStart(newCheckStart)
    setCheckEnd(newCheckEnd)
    setFridgesForProgress(newFridgesForProgress)
    setWeAreInACheck(newWeAreInACheck)
    setUncheckedFridges(newUncheckedFridges)
    setCheckedFridges(newCheckedFridges)

  }, [groupId, tableData, checkingTimesForGroups])





  return <div className={`flex flex-col gap-2`}>

    <h3 className={`text-2xl font-bold font-righteous`} style={{ color: `rgba(${brandstyles.primarycolorrgb},1)` }}>{groupId && tableData.Groups[groupId].GroupName}
    </h3>



    {Object.keys(fridgesForProgress).length > 0 ? <div>
      <p className={`text-xs`}><span className={`font-bold`}>{Object.keys(checkedFridges).length}</span> out of <span className={`font-bold`}>{Object.keys(fridgesForProgress).length}</span> appliances have been checked.</p>
      {groupId && weAreInACheck === true &&
        <CountDownTimer
          endTime={`${checkingTimesForGroups[groupId]['current_check']['check_finishes_at']}`}
          text={`Time left to complete checks`} />}
      {groupId && weAreInACheck === false &&
        <CountDownTimer
          endTime={`${checkingTimesForGroups[groupId]['current_check']['next_check_starts_at']}`}
          text={`Next checking period starts in`} />}
    </div> :
      <p className={`text-xs`}>No fridges have been set up for this area.</p>
    }

    <div className={`flex flex-row flex-wrap gap-2`}>
      {checkedFridges && Object.keys(checkedFridges).map((fridgeId, index) => {
        return <div key={index} className={`bg-green-100 border-green-400 text-xs border rounded px-2 py-1`}>
          <p className={`font-bold`}>{fridges[fridgeId].AssetName}</p>
          <p>({fridgeId})</p>
        </div>
      })}

      {uncheckedFridges && Object.keys(uncheckedFridges).map((fridgeId, index) => {
        return <div key={index} className={`bg-red-100 border-red-400 text-xs border rounded px-2 py-1`}>
          <p className={`font-bold`}>{fridges[fridgeId].AssetName}</p>
          <p>({fridgeId})</p>
        </div>
      })}

    </div>
  </div>
}




function CheckProgressModal({
  setShowModal,
  currentGroupId,
  checkingTimesForGroups,
  showPhone
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  // useEffect(() => {
  //   fetchData()
  // }, [fridges])


  const fetchData = () => {
    if (fridges) {

      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - (48 * 60 * 60 * 1000));
      const startDateTimeStamp = convertDateToTimestamp(startDate)


      const payloadObj: ObjectAny = {
        action: "fetchSingleTableData",
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 999),
        // action: 'fridgetemps',
        // fridgeAction: "requestCurrentChecks",
        assetsToCheck: fridges ? Object.keys(fridges) : [],
        // startDate: startDateTimeStamp
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }




  return <Modal setShowModal={setShowModal} showCloseButton={true}
    showPhone={showPhone}>
    <div className={`flex flex-col gap-6`}>
      {tableData && tableData.Groups ? Object.keys(tableData.Groups).map((groupId, index) => {
        return <ProgressForGroup
          key={index}
          groupId={groupId}
          checkingTimesForGroups={checkingTimesForGroups}
        />
      }) : <Spinner><p>Loading...</p></Spinner>
      }
    </div>
  </Modal>
}
export default CheckProgressModal