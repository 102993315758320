import React, { useState, useEffect, useRef, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import jsQR from "jsqr"
import numeric from 'numeric'
import Spinner from './Spinner'
import { Link } from 'react-router-dom'
import CountDownTimer from './CountdownTimer'
import FullScreenNav from './FullScreenNav'
import Button from '../formfields/Button'
import CheckProgressModal from './CheckProgressModal'




type ThreePointsArray = [[number, number], [number, number], [number, number]]
type ThreeByThreeMatrix = [[number, number, number], [number, number, number], [number, number, number]]

type ObjectAny = {
  [key: string]: any
}
type Props = {
  imageToRead?: HTMLImageElement,
  noQrCanvasRef: React.RefObject<HTMLCanvasElement>,
  straightenedCanvasRef: React.RefObject<HTMLCanvasElement>,
  onCodeFound: (results: any, imageAsStringWithQR: string, imageAsStringWithoutQR: string, training_image_width: number, training_image_height: number) => void
  status?: string
  currentChecks: ObjectAny | null
  isCheckingComplete: boolean
  currentGroupId: string | null
  checkingTimesForGroups: any
  groups: any
  setCurrentGroupId: React.Dispatch<React.SetStateAction<string | null>>
  logoutUrl: string
  loginUrl: string
  setLoggedInState: React.Dispatch<React.SetStateAction<any>>
  setUserData: React.Dispatch<React.SetStateAction<any>>
  setParentCanvasWidth: React.Dispatch<React.SetStateAction<number | null>>,
  setParentCanvasHeight: React.Dispatch<React.SetStateAction<number | null>>
  pauseVideoRef: React.RefObject<boolean>
  showPhone: boolean
  phoneWidth: number
  phoneHeight: number
  overlayElementsDivHeight: string | number,
  pauseCodeDetection: boolean
}






function FullScreenQRCodeReader({
  onCodeFound,
  noQrCanvasRef,
  straightenedCanvasRef,
  status,
  currentChecks,
  isCheckingComplete,
  currentGroupId,
  groups,
  checkingTimesForGroups,
  setCurrentGroupId,
  logoutUrl,
  loginUrl,
  setLoggedInState,
  setUserData,
  setParentCanvasWidth,
  setParentCanvasHeight,
  pauseVideoRef,
  showPhone,
  phoneWidth,
  phoneHeight,
  overlayElementsDivHeight,
  pauseCodeDetection
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const videoElementRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const debugCanvasRef = useRef<HTMLCanvasElement>(null)
  const cameraStartupTimerRef = useRef<number | null>(null)
  const [weAreInACheck, setWeAreInACheck] = useState(null)
  const [videoStarted, setVideoStarted] = useState(false)
  const [waitingForVideoToStart, setWaitingForVideoToStart] = useState<boolean>(false)
  const [fridgesForProgress, setFridgesForProgress] = useState<ObjectAny>({})
  const [numberOfAppliances, setNumberOfAppliances] = useState(0)
  const [numberOfCheckedAppliances, setNumberOfCheckedAppliances] = useState(0)
  const [canvasWidth, setCanvasWidth] = useState(700)
  const [canvasHeight, setCanvasHeight] = useState(1400)
  const [moveCameraHint, setMoveCameraHint] = useState<string | null>(null)
  const moveCameraHintRef = useRef<string | null>(null)
  const [showProgressModal, setShowProgressModal] = useState(false)
  const canvasHeightRef = useRef<number>(700)
  const canvasWidthRef = useRef<number>(700)
  const [videoInputDevices, setVideoInputDevices] = useState<any>(null)
  const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState<any>(null)

  useEffect(() => {
    moveCameraHintRef.current = moveCameraHint
  }, [moveCameraHint])


  useEffect(() => {
    setParentCanvasWidth(canvasWidth)
    setParentCanvasHeight(canvasHeight)
    canvasHeightRef.current = canvasHeight
    canvasWidthRef.current = canvasWidth
  }, [canvasHeight, canvasWidth])


  useEffect(() => {
    const newFridgesForProgress = (currentGroupId && groups && groups[currentGroupId]) ? groups[currentGroupId].Fridges : {}
    const newNumberOfAppliances = Object.keys(newFridgesForProgress).length
    const newNumberOfCheckedAppliances = currentChecks ? Object.keys(currentChecks).length : 0
    currentGroupId && checkingTimesForGroups && checkingTimesForGroups[currentGroupId] && setWeAreInACheck(checkingTimesForGroups[currentGroupId]['current_check']['we_are_in_a_check'])
    setFridgesForProgress(newFridgesForProgress)
    setNumberOfAppliances(newNumberOfAppliances)
    setNumberOfCheckedAppliances(newNumberOfCheckedAppliances)
  }, [currentGroupId, groups, currentChecks, checkingTimesForGroups])



  const x_crop = 170
  // barcode detector stuff
  const barcodeDetectorRef = useRef<any>(null)
  const barcodeModeRef = useRef<string | null>(null)
  const tickHandlerCancelRef = useRef<boolean>(false)

  let foundCodeInfo: any = null

  useEffect(() => {
    //console.log("fridge scanner starting")
    tickHandlerCancelRef.current = false
    return () => {
      //console.log("fridge scanner hiding")
      tickHandlerCancelRef.current = true
    }
  }, [])



  useEffect(() => {
    if (cameraStartupTimerRef.current === null) {
      //@ts-ignore
      cameraStartupTimerRef.current = setInterval(async () => {
          console.log("trying to start camera")
          try {
            await startCamera() 
            console.log("succeeded in starting camera")
            window.clearInterval(cameraStartupTimerRef.current as number)
            cameraStartupTimerRef.current = null

          }  catch (error) {
            console.log("Error starting camera")
          }
     
        }, 500)
    } else {
      //console.log("camera startup timer already running")
    }
  }, [])






  const computeAffineTransform = (src: ThreePointsArray, dst: ThreePointsArray) => {
    // Determine the affine transformation from source triangle to a canonical triangle
    const invSrcMatrix = numeric.inv([
      [src[0][0], src[1][0], src[2][0]],
      [src[0][1], src[1][1], src[2][1]],
      [1, 1, 1]
    ]);

    // Determine the transformation from canonical triangle to destination triangle
    const dstMatrix = [
      [dst[0][0], dst[1][0], dst[2][0]],
      [dst[0][1], dst[1][1], dst[2][1]],
      [1, 1, 1]
    ];

    return numeric.dot(dstMatrix, invSrcMatrix) as ThreeByThreeMatrix;
  }


  const straightenImageOntoCanvas = (code: any, srcElement: any, straightenedCanvasElement: any, croppedCanvasElement: any) => {
    //console.log("straightening")
    const straightened_canvas_ctx = straightenedCanvasElement!.getContext("2d")

    const src_points: ThreePointsArray = [[code.location.topLeftCorner.x, code.location.topLeftCorner.y],
    [code.location.topRightCorner.x, code.location.topRightCorner.y],
    [code.location.bottomLeftCorner.x, code.location.bottomLeftCorner.y]]

    const small = 30
    const qr_size = 90
    const big = small + qr_size

    const dest_points: ThreePointsArray = [[small, small],
    [big, small],
    [small, big]
    ]

    const transform_coeffecients = computeAffineTransform(src_points, dest_points)
    const trans_vals = [transform_coeffecients[0][0], transform_coeffecients[1][0],
    transform_coeffecients[0][1], transform_coeffecients[1][1],
    transform_coeffecients[0][2], transform_coeffecients[1][2]]

    straightened_canvas_ctx?.clearRect(0, 0, 10000, 1000)
    straightened_canvas_ctx!.save()
    straightened_canvas_ctx!.setTransform(trans_vals[0], trans_vals[1], trans_vals[2], trans_vals[3], trans_vals[4], trans_vals[5])
    straightened_canvas_ctx!.drawImage(srcElement, 0, 0)
    straightened_canvas_ctx!.restore()

    const no_qr_context = croppedCanvasElement.getContext("2d")
    const no_qr_width = straightenedCanvasElement!.width - x_crop
    const no_qr_height = straightenedCanvasElement!.height
    no_qr_context!.clearRect(0, 0, no_qr_width + x_crop, no_qr_height)
    no_qr_context!.drawImage(straightenedCanvasElement!, x_crop, 0, no_qr_width, no_qr_height, 0, 0, no_qr_width, no_qr_height)

  }




  const tick = async (lastCapturedAgo = 0) => {
    lastCapturedAgo = lastCapturedAgo + 1
    const videoElement = videoElementRef.current!
    const canvasElement = canvasRef.current!
    const straightenedCanvasElement = straightenedCanvasRef.current!

    if (tickHandlerCancelRef.current) {
      return
    }



    function checkCodePoisition(location: any, capture_width: number, capture_height: number) {
      let reading_ok = true
      let hint = ""
      // this is how much space we need to the right of the qr code, measured incluing the qr code in multiples of its size
      const REQUIRED_WIDTH_MULTIPLIER = 4


      let origin = [location.topLeftCorner.x, location.topLeftCorner.y]
      let top_right = [location.topRightCorner.x, location.topRightCorner.y]
      let bottom_right = [location.bottomRightCorner.x, location.bottomRightCorner.y]
      let bottom_left = [location.bottomLeftCorner.x, location.bottomLeftCorner.y]

      const top_x_diff = top_right[0] - origin[0]
      const top_y_diff = top_right[1] - origin[1]

      const bottom_x_diff = bottom_right[0] - bottom_left[0]
      const bottom_y_diff = bottom_right[1] - bottom_left[1]


      const capture_top_right_x = (REQUIRED_WIDTH_MULTIPLIER * top_x_diff) + origin[0]
      const capture_top_right_y = (REQUIRED_WIDTH_MULTIPLIER * top_y_diff) + origin[1]

      const capture_bottom_right_x = (REQUIRED_WIDTH_MULTIPLIER * bottom_x_diff) + bottom_left[0]
      const capture_bottom_right_y = (REQUIRED_WIDTH_MULTIPLIER * bottom_y_diff) + bottom_left[1]

      const qr_code_size_squared = (top_x_diff * top_x_diff) + (top_y_diff * top_y_diff)

      if (capture_top_right_x > capture_width || capture_bottom_right_x > capture_width) {
        //console.log("Right hand edge is off screen (x)")
        reading_ok = false
        hint = "moveright"
      }
      if (capture_top_right_x < 0 || capture_bottom_right_x < 0) {
        hint = "moveleft"

        reading_ok = false
      }


      if (capture_top_right_y > capture_height || capture_bottom_right_y > capture_height) {
        reading_ok = false
        hint = "movedown"
      }

      if (capture_top_right_y < 0 || capture_top_right_y < 0) {
        //console.log("Right hand edge is off screen (bottom y)")
        reading_ok = false
        hint = "moveup"
      }

      const shortest_side = (capture_width > capture_height) ? capture_height : capture_width
      const shortest_side_multiplier = (1 / REQUIRED_WIDTH_MULTIPLIER) * 0.8
      const max_qr_code_sqaured = (shortest_side * shortest_side_multiplier) * (shortest_side * shortest_side_multiplier)

      if (qr_code_size_squared > max_qr_code_sqaured && !reading_ok) {
        hint = "zoomout"
      }

      if (reading_ok && moveCameraHintRef.current != null) {
        setMoveCameraHint(null)
      }
      if (!reading_ok) {
        //console.log("Move camera hint:")
        //console.log(hint)
        setMoveCameraHint(hint)
      } else {
        //console.log("Reading ok ")
      }




      return {
        reading_ok
      }
    }

    if (videoElement && canvasElement && straightenedCanvasElement && noQrCanvasRef && (!pauseVideoRef.current)) {
      if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
        //@ts-ignore
        const noQrCanvasElement = noQrCanvasRef && noQrCanvasRef!.current!
        const canvas_ctx = canvasElement.getContext("2d", { willReadFrequently: true });

        let capture_width = canvasWidthRef.current
        let capture_height = canvasHeightRef.current

        //@ts-ignore
        canvas_ctx!.drawImage(videoElement, 0, 0, capture_width, capture_height)

        //@ts-ignore
        const imageData = canvas_ctx!.getImageData(0, 0, canvasWidthRef.current, canvasHeightRef.current)


        const promise = findQRCode(imageData, imageData.width, imageData.height).then(async (code) => {

          if (code) {

            foundCodeInfo = {}
            foundCodeInfo["location"] = {}
            //@ts-ignore
            foundCodeInfo.location["topLeftCorner"] = code.location.topLeftCorner
            //@ts-ignore
            foundCodeInfo.location["topRightCorner"] = code.location.topRightCorner
            //@ts-ignore
            foundCodeInfo.location["bottomRightCorner"] = code.location.bottomRightCorner
            //@ts-ignore
            foundCodeInfo.location["bottomLeftCorner"] = code.location.bottomLeftCorner
            //@ts-ignore
            foundCodeInfo.statusText = `V ${code.version}`
            //@ts-ignore
            foundCodeInfo.url = `${code.data}`



            const locationCheckResult = checkCodePoisition(foundCodeInfo.location, capture_width, capture_height)


            const validUrls = ['CHQN.IN', 'QRWL.ME']
            let isFoundUrlValid = false
            validUrls.forEach((url) => {
              if (foundCodeInfo.url.includes(`${url}`)) {
                isFoundUrlValid = true
              }
            })

            if (isFoundUrlValid) {
              lastCapturedAgo = 0
            }
            if (locationCheckResult.reading_ok && isFoundUrlValid) {
              straightenImageOntoCanvas(code, canvasElement, straightenedCanvasElement, noQrCanvasElement)
              const imageAsStringWithQR: string = straightenedCanvasElement!.toDataURL('image/jpeg')
              const imageAsStringWithoutQR: string = noQrCanvasElement!.toDataURL('image/jpeg')
              const w = noQrCanvasElement.width
              const h = noQrCanvasElement.height
              onCodeFound({ codeUrl: foundCodeInfo.url, rawAiResult: null, reading: '', confidence: 0 }, imageAsStringWithQR as string, imageAsStringWithoutQR as string, w, h)
            }
          } else {
            if (lastCapturedAgo > 20 && moveCameraHintRef.current) {
              setMoveCameraHint(null)
            }
          }

          requestAnimationFrame(() => {
            tick(lastCapturedAgo)
          })
        })
      } else {
        console.log(`Video not ready yet [${videoElement.readyState}]`)
        requestAnimationFrame(() => {
          tick(lastCapturedAgo)
        })
      }
    } else {
      if (!pauseVideoRef.current) {
        console.log(`Tick function can't do anything because one of these is null: videoElement=${videoElement} canvasElement=${canvasElement} straightenedCanvasElement=${straightenedCanvasElement} `)
      } else {
        console.log("video paused")
        requestAnimationFrame(() => {
          tick(lastCapturedAgo)
        })
      }
    }


    if (videoElement && canvasElement && straightenedCanvasElement) {

    } else {
      requestAnimationFrame(() => {
        tick(lastCapturedAgo)
      })
    }
  }

  const findQRCode = (imageData: ImageData, width: number, height: number) => {
    return new Promise((resolve, reject) => {
      if (barcodeModeRef.current == null) {
        if ("BarcodeDetector" in window) {
          //console.log("browser barcode detector found")

          barcodeModeRef.current = "browser"
          //@ts-ignore
          barcodeDetectorRef.current = new BarcodeDetector({
            formats: ["qr_code"]
          });
        } else {
          //console.log("no barcode detector in browser, using jsqr")
          barcodeModeRef.current = "jsqr"
        }
        resolve(null)
      }

      if (barcodeModeRef.current == "browser") {
        barcodeDetectorRef.current.detect(imageData).then((detector_result: any[]) => {
          if (detector_result.length > 0) {
            console.log("🏁 broswer found code, trying jsQR")
            const code = jsQR(imageData.data, width, height, {
              inversionAttempts: "dontInvert",
            })
            resolve(code)

          } else {
            resolve(null)
          }

        })
      } else {
        const code = jsQR(imageData.data, width, height, {
          inversionAttempts: "dontInvert",
        })
        resolve(code)
      }
    })
  }


  const getVideoConstraints = async () => {
    //console.log("figuring out what size video to ask for")
    const screen_height = window.screen.height  //  window.innerHeight
    const screen_width = window.screen.width

    let window_width = window.innerWidth //window.screen.width
    const window_height = window.innerHeight
    const screen_aspect_ratio = screen_width / screen_height

    const aspect_ratio_to_use = (screen_height > screen_width) ? 1 / screen_aspect_ratio : screen_aspect_ratio
    window_width = window_width * 2

    const max_width = 1280

    if (window_width > max_width) {
      window_width = max_width
    }


    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    setVideoInputDevices(videoDevices)


    let deviceLabel = videoDevices[videoDevices.length - 1]['label']
    let deviceId = videoDevices[videoDevices.length - 1]['deviceId']

    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
      console.log("iphone detected")
      if (videoDevices.length >= 2) {
        console.log("Multiple cameras detected")
        deviceLabel = videoDevices[1]['label']
        deviceId = videoDevices[1]['deviceId']
      }
    }


    // console.log(`🎥 ${videoDevices.length} cameras found`)

    for (const device of videoDevices) {
      // console.log(`🎥 ${device.label} ${device.deviceId}`)
    }

    const options: any = {
      //@ts-ignore
      // resizeMode: 'crop-and-scale',
      facingMode: "environment",
      width: { 'exact': window_width },
      aspectRatio: aspect_ratio_to_use,
      zoom: 1
    }


    const deviceFromLocalStorage = localStorage.getItem('selectedVideoInputDevice')
    if (deviceFromLocalStorage) {
      setSelectedVideoInputDevice(deviceFromLocalStorage)
      options['deviceId'] = deviceFromLocalStorage
    } else {
      options['deviceId'] = deviceId
    }


    //console.log(options)

    return options
  }

  const startCamera = async () => {
    //console.log("attempting to start camera")
    if (videoElementRef.current) {
      const videoElement = videoElementRef.current
      setWaitingForVideoToStart(true)
      requestAnimationFrame(async () => {
        const videoConstraints = await getVideoConstraints()
        await navigator.mediaDevices.getUserMedia({ video: videoConstraints }).then(async function (stream) {
          setWaitingForVideoToStart(false)
          const streamTrack = stream.getVideoTracks()[0]
          const streamCapabilities = streamTrack.getCapabilities()
          const streamSettings = streamTrack.getSettings()
          const desiredCapabilities = getVideoConstraints()
          // {
          //              'focusMode': 'manual', // use these to deliberately break focus for testing
          //              'focusDistance':  3.5,
          //      'focusMode': 'continuous',
          //      'resizeMode': 'crop-and-scale',
          //      'zoom': 1
          //    }
          for (const [key, value] of Object.entries(desiredCapabilities)) {
            //console.log(`seeing if i can set ${key} to ${value}`)
            if (Object.keys(streamCapabilities).includes(key)) {
              //console.log("capability found, attempting to set value")
              const newConstraints: any = {}
              newConstraints[key] = value
              streamTrack.applyConstraints(newConstraints).then(() => {
                // Do something with the track such as using the Image Capture API.
                //console.log(` applied constraints to video stream successfully: ${key} ${value}`)
              })
                .catch((e) => {
                  console.log(`error applying constraints to video stream${e}`)
                });
            } else {
              console.log(`cant set ${key} on this device`)
            }
          }
          videoElement.srcObject = stream
          videoElement.setAttribute("playsinline", "true") // required to tell iOS safari we don't want fullscreen
          //console.log("calling videoElement.play()")
          const playPromise = videoElement.play()
          if (playPromise !== undefined) {
            playPromise.then(_ => {
              //console.log("Video started")
              let video_height = videoElement.videoHeight
              let video_width = videoElement.videoWidth
              // let screen_width = window.screen.width
              let screen_height = window.innerHeight
              let screen_width = window.innerWidth

              const height_scale_factor = screen_height / video_height
              const width_scale_factor = screen_width / video_width
              const scale_factor = (width_scale_factor > height_scale_factor) ? width_scale_factor : height_scale_factor
              video_height = video_height * scale_factor
              video_width = video_width * scale_factor
              //console.log(`Scaling width from w,h (${videoElement.videoWidth},${videoElement.videoHeight}) to (${video_width},${video_height})`)
              setCanvasHeight(video_height)
              setCanvasWidth(video_width)
              setVideoStarted(true)
              const streamTrack = stream.getVideoTracks()[0]
              const streamCapabilities = streamTrack.getCapabilities()
              // console.log('🎥 streamTrack.getCapabilities() after camera started')
              // console.log(JSON.stringify(streamCapabilities))
              const streamSettings = streamTrack.getSettings()
              // console.log('🎥 streamTrack.getSettings() after camera started')
              // console.log(JSON.stringify(streamSettings))
              function resizeFunction(event: Event) {
                // console.log(`Resize funciton called ${event.type}`)
                let video_height = videoElement.videoHeight
                let video_width = videoElement.videoWidth
                // let screen_width = window.screen.width
                let screen_height = window.innerHeight
                let screen_width = window.innerWidth

                const height_scale_factor = screen_height / video_height
                const width_scale_factor = screen_width / video_width
                const scale_factor = (width_scale_factor > height_scale_factor) ? width_scale_factor : height_scale_factor
                video_height = video_height * scale_factor
                video_width = video_width * scale_factor
                //console.log(`Scaling width from w,h (${videoElement.videoWidth},${videoElement.videoHeight}) to (${video_width},${video_height})`)
                setCanvasHeight(video_height)
                setCanvasWidth(video_width)
              }
              window.addEventListener("orientationchange", resizeFunction)
              window.addEventListener("resize", resizeFunction)
              //console.log(`👀 video started w: ${video_width} h: ${video_height}`)
              requestAnimationFrame(() => {
                tick()
              })
            })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log(`error starting camera ${error} `)
              });
          }


        })
      })


    } else {
      console.log(`couldn't start camera because video element is not current yet ${videoElementRef.current} ${canvasRef.current}`)

    }
  }




  return <div className={`h-full w-full flex flex-col items-center justify-center relative`}>





    {showProgressModal && <CheckProgressModal
      setShowModal={setShowProgressModal}
      currentGroupId={currentGroupId}
      checkingTimesForGroups={checkingTimesForGroups}
      showPhone={showPhone}
    />}





    {!videoStarted && <div
      className={`absolute h-full w-full z-10 top-0 left-0 flex flex-col items-center justify-center`}>
      <div className={`bg-white rounded py-3 px-4 shadow`}>
        <Spinner>Camera starting... </Spinner>
      </div>
    </div>}




    <div
      className={`${videoStarted ? 'flex' : 'hidden'} absolute z-10 flex-col items-center justify-between gap-6 p-3`}
      style={{
        top: `${showPhone ? `0px` : `0px`}`,
        width: `${showPhone ? `${phoneWidth - 30}px` : `100%`}`,
        height: `${showPhone ? `${phoneHeight - 95}px` : overlayElementsDivHeight}`
      }}
    >

      <div className={`w-full flex flex-row items-center justify-between gap-4 `}>
        <Link to={'/'}>
          <img
            src={'/logo-white-temperature-logging.svg'}
            alt='ChecQR Logo'
            className={`border-0 w-48`}
          />
        </Link>


        <FullScreenNav
          logoutUrl={logoutUrl}
          loginUrl={loginUrl}
          setLoggedInState={setLoggedInState}
          setUserData={setUserData}
          showPhone={showPhone}
          videoInputDevices={videoInputDevices}
          selectedVideoInputDevice={selectedVideoInputDevice}
          setSelectedVideoInputDevice={setSelectedVideoInputDevice}
        />
      </div>
      {videoStarted &&
        <div className={`flex-1 w-full flex justify-start items-center`}>

          <div className={`animate-sizepulse h-48 w-48 rounded border-white border-4 p-4 flex flex-col items-center justify-center text-center`}
            style={{
              backgroundColor: `rgba(0, 0, 0, 0.3)`
            }}
          >

            {!moveCameraHint && <h3 className={`text-xl text-white font-bold font-righteous drop-shadow-sm`}> Position QR code here</h3>}

            {moveCameraHint && moveCameraHint === 'zoomout' &&
              <div>
                <p className={`text-4xl text-white`}>↔️</p>
                <h3 className={`text-xl text-white font-bold font-righteous drop-shadow-sm`}> Zoom out</h3>
              </div>}

            {moveCameraHint && moveCameraHint === 'moveleft' &&
              <div>
                <p className={`text-4xl text-white`}>⬅️📱</p>
                <h3 className={`text-xl text-white font-bold font-righteous drop-shadow-sm`}> Move phone left</h3>
              </div>}

            {moveCameraHint && moveCameraHint === 'moveright' &&
              <div>
                <p className={`text-4xl text-white`}>➡📱</p>
                <h3 className={`text-xl text-white font-bold font-righteous drop-shadow-sm`}> Move phone right</h3>
              </div>}


            {moveCameraHint && moveCameraHint === 'moveup' &&
              <div>
                <p className={`text-4xl text-white`}>⬆️📱</p>
                <h3 className={`text-xl text-white font-bold font-righteous drop-shadow-sm`}> Move phone up</h3>
              </div>}

            {moveCameraHint && moveCameraHint === 'movedown' &&
              <div>
                <p className={`text-4xl text-white`}>⬇️📱</p>
                <h3 className={`text-xl text-white font-bold font-righteous drop-shadow-sm`}> Move phone down</h3>
              </div>}



          </div>
        </div>
      }

      <div className={`w-full bg-white rounded py-3 px-4 drop-shadow-lg flex flex-row gap-6 justify-between`}>


        {currentGroupId && tableData && tableData.Groups && tableData.Groups[currentGroupId] && <div>
          <h3 className={`text-md font-bold font-righteous`}
            style={{ color: `rgba(${brandstyles.primarycolorrgb},1)` }}
          >{tableData.Groups[currentGroupId]['GroupName']}</h3>
          <p className={`text-xs`}>
            {numberOfCheckedAppliances === numberOfAppliances && '✅ '}
            {numberOfCheckedAppliances !== numberOfAppliances && weAreInACheck === false && '🚨 '}
            <span className={`font-bold`}>{numberOfCheckedAppliances}</span> of <span className={`font-bold`}>{numberOfAppliances}</span> checks completed
            {weAreInACheck === false && ' during last check period'}
          </p>
          {currentGroupId && checkingTimesForGroups && checkingTimesForGroups[currentGroupId] && <div>
            {weAreInACheck === true &&
              <CountDownTimer
                endTime={`${checkingTimesForGroups[currentGroupId]['current_check']['check_finishes_at']}`}
                text={`Time left to complete checks`} />}
            {weAreInACheck === false &&
              <CountDownTimer
                endTime={`${checkingTimesForGroups[currentGroupId]['current_check']['next_check_starts_at']}`}
                text={`Next checking period starts in`} />}

          </div>}
        </div>}

        <Button
          onClick={() => { setShowProgressModal(true) }}
          text="View details"
          variant={'primary'}
          fullwidth={false}
        />

      </div>
    </div>


    <div className={'hidden'}>
      <video
        ref={videoElementRef}
        autoPlay={true}
        muted={true}
        playsInline={true}
      // style={{ outline: '2px solid red' }}
      ></video>
    </div>


    {videoStarted && <>

      <div className={`${showPhone ? `` : ``} bg-black overflow-hidden`}
        style={{
          width: `${showPhone ? `${phoneWidth - 20}px` : '100vw'}`,
          height: `${showPhone ? `${phoneHeight - 80}px` : '100vh'}`,
          // borderRadius: `${showPhone ? '40px' : ''}`
        }}
      >

        <canvas
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          className={`opacity-75`}
        ></canvas>
      </div>

      <canvas
        ref={straightenedCanvasRef}
        width={600}
        height={250}
        className={`hidden w-full border-gray-400 rounded hover:opacity-90-md bg-gray-300`}
      ></canvas>

      <canvas
        ref={debugCanvasRef}
        width={canvasWidth}
        height={canvasHeight}
        className={`hidden w-full border-red-400 rounded hover:opacity-90-md bg-gray-300`}
      ></canvas>
    </>}



  </div>

}

export default FullScreenQRCodeReader