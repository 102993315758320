import { useState, useRef, useEffect, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Spinner from './Spinner'
import Button from '../formfields/Button'

type Props = {
  loginUrl: string
  loggedInState: string | null
  assetToFindOrgFrom: string | null
}

function Home({
  loginUrl,
  loggedInState,
  assetToFindOrgFrom
}: Props) {


  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const [username, setUsername] = useState<string | null>(null)



  const sendData = () => {
    const payload = JSON.stringify({
      action: 'guestLogin',
      guestLoginAction: 'login',
      username: username,
      assetId: assetToFindOrgFrom || `${window.location.pathname.replaceAll('/','')}`,
      browserInfo: {

      }
    })
    sendMessageToWebsocket(payload)
  }


  return <div className={`h-screen flex flex-col items-center text-white text-center`}
    style={{ backgroundColor: `rgba(${brandstyles.primarycolorrgb},1)` }}
  >
    <div className={`h-full max-w-xl w-full p-5 mb-12 gap-6 flex flex-col items-center justify-center`}>

      <img src={`logo-white-no-strapline.svg`} alt='ChecQR Logo' className={`w-64`} />
      <h2 className={`text-3xl  font-bold font-righteous`}>
        ChecQR Fridge Scanner
        </h2>

      {!loggedInState && <Spinner><p className={`text-sm`}>Logging you in....</p></Spinner>}

      {loggedInState && loggedInState === 'failed' && <p>⛔️ Session expired. Please log in again.</p>}


      {loggedInState &&  <div className={`flex flex-col gap-2 items-center justify-center`}>

        <div className={`flex flex-col gap-2`}>
          <input
            type={'text'}
            placeholder={'Your name'}
            className={`rounded text-xl px-5 py-3 text-sm text-gray-700`}
            onChange={(e) => setUsername(e.target.value)}
            value={username || ''}
          />
          <Button
            onClick={sendData}
            text={'Continue'}
            size={'big'}
            disabled={username && username.length > 1 ? false : true}
            variant={username && username.length > 1 ? 'secondary' : 'gray'}
          />
        </div>
        <a href={loginUrl} className={`mt-2 underline text-sm`}>Or log in with email</a>
      </div>}
    </div>
  </div>
}
export default Home