

import { useState, useRef, useEffect, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  onClick?: any
  internalLinkUrl?: string
  externalLinkUrl?: string
  text: string
  disabled?: boolean
  variant?: 'primary' | 'secondary' | 'warning' | 'gray'
  size?: 'big' | 'small'
  fullwidth?: boolean
  align?: string
}

function Button({
  onClick,
  internalLinkUrl,
  externalLinkUrl,
  text,
  disabled,
  variant,
  size,
  fullwidth = true,
  align = 'center'
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  const classString = `
  inline-block font-bold text-lg rounded hover:opacity-90
  ${fullwidth === true && 'w-full'}
  ${size !== 'small' && size !== 'big' && 'text-sm px-3 py-2'}
  ${size === 'big' && 'text-lg px-5 py-3'}
  ${size === 'small' && 'text-xs px-3 py-2'}
  ${variant === 'gray' && 'bg-gray-400 text-gray-700'}
  ${variant === 'warning' && 'bg-red-200 text-red-900'}
  ${align === 'left' ? 'text-left' : 'text-center'}
  `

  let styleObject = {}
  if (variant === 'primary' || !variant) {
    styleObject = { color: 'white', backgroundColor: `rgba(${brandstyles.primarycolorrgb},1)` }
  } if (variant === 'secondary') {
    styleObject = { color: 'white', backgroundColor: `rgba(${brandstyles.secondarycolorrgb},1)` }
  }


  return <>

    {internalLinkUrl && <Link
      to={internalLinkUrl}
      className={classString}
      style={styleObject}
    >{text}</Link>}

    {externalLinkUrl && <a
      href={externalLinkUrl}
      target={'_blank'}
      className={classString}
      style={styleObject}
    >{text}</a>}

    {onClick && <button
      onClick={onClick}
      className={classString}
      disabled={disabled ? disabled : false}
      style={styleObject}
    >{text}</button>}


  </>
}
export default Button