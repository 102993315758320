import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from './Modal'

type Props = {
  setSubscriptionExpiringWithoutPayment: React.Dispatch<React.SetStateAction<any>>
}


function SubscriptionExpiring({
  setSubscriptionExpiringWithoutPayment
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  return <Modal>
    <div className={`mb-3 flex flex-col items-center justify-center text-center`}>
      <img src={`/tip-icon.png`} className={`mb-2 w-12`} alt='' />
      <h2 className={`text-2xl font-bold font-righteous text-red-600`}
        style={{ color: `rgba(${brandstyles.primarycolorrgb},1)` }}>
        Your free trial expires soon </h2>
      <p>Add a payment method to continue using ChecQR.</p>
    </div>
    <div className={`flex flex-row gap-2`}>
    <Button
      externalLinkUrl={`https://dashboard.checqr.net/payments`}
      text={`Add payment method`}
      variant={`primary`}
      // size={'big'}
    />
    <Button
      onClick={() => setSubscriptionExpiringWithoutPayment(false)}
      text={`Not right now`}
      variant={`gray`}
      // size={'big'}
    /></div>
  </Modal>
}

export default SubscriptionExpiring