
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from './Modal'
type ObjectAny = {
  [key: string]: any
}

type Props = {
  logoutUrl: string
}


function SubscriptionExpired({
  logoutUrl
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const organisations: ObjectAny = userData ? JSON.parse(userData.organisationsForUser) : {}
  const [showOrgsMenu, setShowOrgsMenu] = useState(false)


  const handleLogout = () => {
    // setLoggedInState('loggedOut')
    // setUserData(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    localStorage.removeItem('guestLogin')
    window.location.href = logoutUrl
  }

  return <Modal>
    <div className={`mb-3 flex flex-col items-center justify-center text-center`}>
      <img src={`/red-alert-icon.png`} className={`mb-2 w-12`} alt='' />
      <h2 className={`text-2xl font-bold font-righteous text-red-600`}>
        Your subscription has expired</h2>
      <p>You can add payment details on your ChecQR dashboard.</p>
    </div>


    <Button
      externalLinkUrl={`https://dashboard.checqr.net/payments`}
      text={`Add payment method`}
      variant={`warning`}
      size={'big'}
    />


    {organisations && Object.keys(organisations).length > 1 && switchCurrentOrganisation &&
      <div className={`pt-2 w-full flex flex-col items-center justify-center gap-2`}>
        {!showOrgsMenu && <Button
          onClick={() => { setShowOrgsMenu(true) }}
          text={`Switch organisation`}
          variant={`gray`}
          size={'big'}
        />}
        {showOrgsMenu && <div className={`pt-2 w-full flex flex-col gap-2 items-center justify-center`}>
          <div className={`w-full flex flex-row items-center justify-center gap-2`}>
            Select a different organisation:
          </div>
          <div className={`w-full flex flex-wrap flex-row gap-2 items-center justify-center`}>
            {
              Object.keys(organisations).map((id, index) => {
                return <div
                  className={`${currentOrganisation === id && 'hidden'}
                  rounded bg-gray-300 text-gray-700 font-bold px-3 py-2 text-xs hover:opacity-80 cursor-pointer`}
                  key={index}
                  onClick={() => {
                    setShowOrgsMenu(false)
                    switchCurrentOrganisation(id, true)
                  }}>
                  {organisations[id]}
                </div>
              })
            }
          </div>
          <button
            onClick={() => { setShowOrgsMenu(false) }}
            className={`underline text-xs text-gray-700`}
          >
            Cancel</button>
        </div>}
      </div>}

    <div className={`mt-2`}>
      <Button
        onClick={handleLogout}
        text={`Log out`}
        variant={`gray`}
        size={'big'}
      />
    </div>
  </Modal>
}

export default SubscriptionExpired